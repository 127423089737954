import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	props: {
		currentActive: {
			type: String,
			default: ''
		},
		tabs: {
			type: Array,
			default: (() => [])
		}
	},
	data() {
		return {
			q: {},
			queryForm: {},
			form: {},
			page: {
				size: 10,
				sizes: [10, 20, 50, 100],
				current: 1,
				total: 0
			},
			namespace: ''
		}
	},
	methods: {
		searchEvent() {
			this.q = {
				...this.queryForm
			}
			Object.keys(this.q).forEach(key => {
				if (this.q[key] === '') {
					delete this.q[key]
				}
			})
			this.page.current = 1
			this.getList()
		},
		handleCommand(command) {
			if (this.namespace === '') return
			const ids = this.multipleSelection.map(v => v.id)
			const active = command === 'active' ? 'true' : 'false'
			this.adminLayout.showLoading()
			const payload = {
				ids,
				active
			}
			this.$store.dispatch(`${this.namespace}/activeItems`, payload)
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
		},
		handleList(content) {
			this.tableData = content
		},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			const payload = {
				params: {
					...this.page,
					...this.q
				},
				trigger
			}
			return this.$store.dispatch(`${this.namespace}/getList`, payload)
		},
		changeActive(item) {
			if (this.namespace === '') return
			const {
				id,
				active
			} = item
			// publish or uppublish item
			this.adminLayout.showLoading()
			const payload = {
				id,
				active
			}
			this.$store.dispatch(`${this.namespace}/activeItem`, payload)
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
		},
		prepareFormData() {
			return {
				...this.form
			}
		},
		refreshRow(row) {},
		async asyncUpdateNestedForm(data) {
			return new Promise((resolve, reject) => {
				resolve(data)
			})
		},
		doCreateOrUpdate() {
			if (this.namespace === '') return
			console.log(this.form)
			this.$refs.form.validate(v => {
				if (v) {
					this.adminLayout.showLoading(true)
					const {
						id
					} = this.form
					this.asyncUpdateNestedForm(this.prepareFormData())
					.then((body) => {
						if (id) {
							const payload = {
								id,
								body
							}
							this.$store.dispatch(`${this.namespace}/updateItem`, payload)
								.then(res => {
									this.$showSuccess('更新成功')
									this.adminLayout.hideLoading()
									this.visible.add = false
									if (!this.refreshRow(res)) {
										this.getList(this.page.current)
									}
								})
								.catch(err => {
									this.$showErrMsg(err)
									this.adminLayout.hideLoading()
								})
							return
						}
						const payload = {
							...body,
							active: true
						}
						this.$store.dispatch(`${this.namespace}/newItem`, payload)
							.then(res => {
								this.$showSuccess('添加成功')
								this.adminLayout.hideLoading()
								this.visible.add = false
								this.getList()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					})
					.catch(err => {
						this.adminLayout.hideLoading(true)
					})
				}
			})
		},
		getId(item) {
			return item.id
		},
		fillForm(data) {
			this.form = data
		},
		editRow(item, copy=false) {
			if (this.namespace === '') return
			this.adminLayout.showLoading()
			this.$store.dispatch(`${this.namespace}/getItem`, this.getId(item))
				.then(data => {
					this.adminLayout.hideLoading()
					const form = copy ? {...data, id: null} : data
					this.fillForm(form)
					this.visible.add = true
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
		},
		delRow(item) {
			this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (this.namespace === '') return
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/delItem`, this.getId(item))
					.then(data => {
						this.adminLayout.hideLoading()
						this.getList(this.page.current)
					})
					.catch(err => {
						this.adminLayout.hideLoading()
					})
			})
		}
	}
}

<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false"></form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="form.website=queryForm.website;visible.add=true">添加配置</el-button>
				</form-item>
			</template>
		</advanced-search-bar>
		<divider />
		<el-table :data="tableData" style="width: 100%" height="auto">
		      <el-table-column prop="website" label="站点" min-width="180">
		      </el-table-column>
			  <el-table-column prop="code" label="编码" min-width="180">
			  </el-table-column>
			  <el-table-column prop="name" label="名称" min-width="180">
			  </el-table-column>
			  <el-table-column label="国家" min-width="300">
				  <template slot-scope="scope">
					  {{scope.row.countries.join(', ')}}
				  </template>
			  </el-table-column>
			  <el-table-column prop="note" label="备注" min-width="200">
			  </el-table-column>
		      <el-table-column label="更新时间" min-width="200">
		      	<template slot-scope="scope">
					{{(scope.row.updatedAt || scope.row.createdAt)|formatDate}}
				</template>
		      </el-table-column>
			  <el-table-column label="更新人" min-width="150">
			  	<template slot-scope="scope">
			  		{{(scope.row.updatedBy || scope.row.createdBy).name}}
			  	</template>
			  </el-table-column>
		      <el-table-column label="操作" min-width="100">
		      	<template slot-scope="scope">
					<el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
		      	</template>
		      </el-table-column>
		    </el-table>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" style="min-width:900px;">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="60px">
				<el-form-item-sites :abbr="true" v-model="form.website" label-width="60px" prop="website"></el-form-item-sites>
				<el-form-item label="编号:" prop="code">
					<el-input v-model.trim="form.code" @input="form.code=form.code.replace(/[^a-zA-Z0-9]/, '').toUpperCase()" :readonly="selectRow"></el-input>
				</el-form-item>
				<el-form-item label="名称:">
					<el-input v-model.trim="form.name"></el-input>
				</el-form-item>
				<el-form-item label="国家:" prop="countryCodes">
					<el-select v-model="form.countryCodes" clearable filterable multiple class="w-100">
						<el-option v-for="country in countries" :label="country.name" :value="country.code" :key="country.code">
							<div class="d-flex flex-row align-items-center justify-content-between">
								<div>{{country.name}}</div>
								<div>{{country.code}}</div>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input v-model.trim="form.note"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="saveCountries">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	import config from './minixs/index.js'
	import Lodash from 'lodash'
	const DEFAULT_FORM = {
		code: '',
		countryCodes: [],
		note: '',
		website: '',
		name: ''
	}
	export default {
		mixins:[config],
		data() {
			return {
				queryForm: {
					countryCode: '',
					languageCode: '',
					active: ''
				},
				tableData: [],
				q: {},
				visible: {
					add: false
				},
				form: {
					countryCode: ''
				},
				rules: {
					code: [{
						required: true,
						message: '编号不能为空'
					}, {
						min:3,
						max: 3,
						message: '编号长度为3个字符'
					}],
					countryCodes: [{
						required: true,
						type: 'array',
						message: '国家不能为空'
					}],
					website: [{
						required: true,
						message: '站点不能为空'
					}]
				},
				selectRow: null,
				namespace: 'fb-contents-country'
			}
		},
		computed: {
			...mapState({
				countries: state => state.content.countries
			})
		},
		mounted() {
			this.getCountries()
		},
		methods: {
			...mapActions([
				'getCountries'
			]),
			searchEvent() {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/getSiteCountries`, this.queryForm.website)
				.then(res => {
					this.tableData = res
				})
				.catch(err => {
					this.$showErrMsg(err)
				})
				.finally(() => {
					this.adminLayout.hideLoading()
				})
			},
			saveCountries() {
				this.adminLayout.showLoading(true)
				this.$refs['form'].validate(v => {
					if (v) {
						const apiKey = this.selectRow ? `${this.namespace}/renewCountries` : `${this.namespace}/newCountries`
						this.$store.dispatch(apiKey, this.form)
						.then(res => {
							this.queryForm.website = this.form.website
							this.visible.add = false
							this.searchEvent()
						})
						.catch(err => {
							this.$showErrMsg(err)
						})
						.finally(() => {
							this.adminLayout.hideLoading()
						})
					}
				})
			},
			editRow(row) {
				this.selectRow = row
				this.form = {
					...Lodash.cloneDeep(DEFAULT_FORM),
					countryCodes: row.countries || [],
					...row
				}
				this.visible.add = true
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.selectRow = null
					this.form = Lodash.cloneDeep(DEFAULT_FORM)
				}
			}
		}
	}
</script>

<style>
</style>
